import React from 'react';
import Layout from '../layouts/nl';
import OtherInvestments from '../components/OtherInvestments';
import { Link } from 'gatsby';
import iconTransaction from '../assets/images/icons/icon-transaction.svg';
import iconSkills from '../assets/images/icons/icon-skills.svg';
import iconStatistics from '../assets/images/icons/icon-statistics.svg';
import Scroll from './../components/Scroll';
import ApolloClient from 'apollo-boost';
import { gql } from "apollo-boost";

export default class InvestmentFocusPage extends React.Component {

    client = new ApolloClient({
        uri: `${process.env.GATSBY_API_URL}/graphql`,
    });

    constructor(props) {
        super(props);
        this.state = {
            related_portfolio: null
        }
    }

    componentDidMount() {
        this.getRelatedPortfolio();
    }

    getRelatedPortfolio() {
        this.client.query({
            fetchPolicy: 'no-cache',
            query: gql`
                {
                    portfolionls (
                        where: {
                          active: true
                        }
                      ) {
                        slug
                        company_name
                        aquired
                        company_logo {
                            url
                        }
                        portfolio_image {
                            url
                        }
                        Sold
                        category
                    }
                }
            `
        }).then(
            (response) => {
                if (response.data.portfolionls.length > 0) {
                    const randomPortfolioIndex = Math.floor(Math.random() * response.data.portfolionls.length)
                    this.setState({related_portfolio: response.data.portfolionls[randomPortfolioIndex]});
                } else {
                    this.setState({related_portfolio: null});
                }
            }
        )
    }

  render() {
    return (
        <Layout>
        <header className="masthead invested-criteria-wrapper" id="header">
            <div className="center-block text-center col-sm-12">
                <h1 className="mx-auto my-0 homepage-main-text">
                    Criteria we look for in potential investments
                </h1>
            </div>
        </header>
        <section className="container investment-criteria-container content-wrapper">
            <div className="col-sm-12">
                <h1 className="dark-left-heading">Investeringsfocus</h1>
                <span className="horizontal-line gray-background"></span>

                <div className="row">
                    <div className="col-lg-4 float-left investment-focus-section-wrapper">
                        <img src={iconSkills} alt="" className="no-top-margin investment-icon"/>
                        <h4 className="fs-1_5rem">Sectoren & Ondernemingen<span className="mobile-hidden"><br/>&nbsp;</span></h4>
                        <table className="invesment-focus-table">
                            <tbody>
                                <tr><td>Actief in productie, (technische) groothandel of zakelijke dienstverlening</td></tr>
                                <tr><td>Omzet tussen € 10M en € 100M</td></tr>
                                <tr><td>Hoofdkantoor in Nederland of Duitsland</td></tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-lg-4 float-left investment-focus-section-wrapper">
                        <img src={iconStatistics} alt="Market criteria" className="no-top-margin investment-icon"/>
                        <h4 className="fs-1_5rem">Transactie<span className="mobile-hidden"><br/>&nbsp;</span></h4>
                        <table className="invesment-focus-table">
                            <tbody>
                                <tr><td>Buy-outs, groei, opvolgingsplanning, uitdagende financiële situatie, transformatie, carve-outs</td></tr>
                                <tr><td>Aandelenparticipatie tot € 12M</td></tr>
                                <tr><td>Controlerend belang, flexibel in deal en kapitaalstructuur</td></tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-lg-4 float-left investment-focus-section-wrapper">
                        <img src={iconTransaction} alt="Transactional criteria" className="no-top-margin investment-icon"/>
                        <h4 className="fs-1_5rem">Toegevoegde waarde<span className="mobile-hidden"><br/>&nbsp;</span></h4>
                        <table className="invesment-focus-table">
                            <tbody>
                                <tr><td>Snelle besluitvorming tijdens en na de transactie</td></tr>
                                <tr><td>Team is een mix van ondernemers & operationele en financiële professionals</td></tr>
                                <tr><td>Betrokkenheid bij strategie, prioritering, definiëren en faciliteren van transformaties</td></tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3 float-left investment-focus-section-wrapper">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="gray-rectangle">
                                    <p className="black-color">Voldoet jouw bedrijf aan bovenstaande voorwaarden en ben je bereid het volledige potentieel van jouw bedrijf the realiseren? </p>
                                    <br/>
                                    <Link to="/?scroll-to=contact">
                                        <span className="blue-color"><strong>Neem contact op voor het maken van een afspraak</strong></span>
                                    </Link>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="clearfix"></div>

            <div className="narrow-wrapper col-sm-12 investment-focus-section-wrapper other-investments">
                <OtherInvestments locale={'nl-NL'}/>
            </div>
        </section>
    
        <br/>
        <Scroll type="id" element="header">
                <div className="investment-focus-back back-to-top-wrapper">
                <a href="?scroll-to=header">
                    <i className="fas fa-angle-double-up"></i>
                    <br />
                    <span className="back-to-top">Terug naar boven</span>
                </a>
            </div>
        </Scroll>
      </Layout>
    )

  }

}

